import React from 'react';
import { Testimonial } from '@/types';
import { graphql, useStaticQuery } from 'gatsby';
import ArteliaLogo from '@/assets/img/logos-clients/light-mode/artelia.svg';
import ArteliaLogoDark from '@/assets/img/logos-clients/dark-mode/artelia.svg';

export default function Artelink(): Testimonial {
  const data = useStaticQuery(graphql`
    query {
      picture: file(
        relativePath: { eq: "projects/artelink/patrick-agnoletto.jpeg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `);

  return {
    fullName: 'Patrick Agnoletto',
    job: 'testimonialJobArtelink',
    company: 'Artelia',
    companyLogo: <ArteliaLogo />,
    companyLogoDark: <ArteliaLogoDark />,
    picture: data.picture,
    content: 'testimonialContentArtelink',
    link: 'linkedin.com/in/patrick-agnoletto',
  };
}
